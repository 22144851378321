import React from "react";
import { Controller } from "react-hook-form";
import PhoneInputWithCountrySelect, { 
    getCountryCallingCode, 
    isPossiblePhoneNumber, 
    isValidPhoneNumber, 
    parsePhoneNumber 
} from "react-phone-number-input";

export default function FormPhoneField({ control, name, label, ...props }) {
    return (
        <Controller
            name={name}
            control={control}
            defaultValue={{dial_code:"+971"}}
            rules={{
                validate: (val) => {
                    if (!val || typeof val !== 'object') {
                        return "Phone number is required";
                    }
                    const { code, dial_code, number, value } = val;
                    if (!code || !dial_code || !number) {
                        return "Incomplete phone number data";
                    }
                    if (!isPossiblePhoneNumber(value)) {
                        return "Phone number is not possible";
                    }
                    if (!isValidPhoneNumber(value)) {
                        return "Phone number is not valid";
                    }
                    return true;
                },
            }}
            render={({ field, fieldState: { error } }) => {
                const handleChange = (value) => {
                    if (value) {
                        try {
                            const parsedNumber = parsePhoneNumber(value);
                            // Preserve existing dial_code if possible
                            const existingDialCode = field.value?.dial_code || `+${parsedNumber.countryCallingCode}`;
                            
                            field.onChange({
                                code: parsedNumber?.country,
                                dial_code: existingDialCode,
                                number: parsedNumber?.nationalNumber,
                                value: value
                            });
                        } catch (error) {
                            console.error("Error parsing phone number", error);
                            field.onChange(null);
                        }
                    } else {
                        field.onChange(null);
                    }
                };

                return (
                    <label style={{ width: "100%" }}>
                        {label} {props?.required && <span className="required-label">*</span>}
                        <PhoneInputWithCountrySelect
                            {...field}
                            value={field.value ? field.value.dial_code + field.value.number : undefined}
                            className="phoneInput"
                            defaultCountry="AE"
                            placeholder="Enter Your Phone *"
                            onChange={handleChange} 
                            onCountryChange={(country) => {
                                // Update country code while preserving existing input
                                if (field.value) {
                                    const newDialCode = `+${getCountryCallingCode(country)}`;
                                    field.onChange({
                                        ...field.value,
                                        code: country,
                                        dial_code: newDialCode
                                    });
                                }
                            }}
                        />
                        {error && (
                            <div className="text-red-500 my-1">
                                {error.message}
                            </div>
                        )}
                    </label>
                );
            }}
        />
    );
}