import React, { useEffect, useState, useLayoutEffect } from "react";
import Pages from "../route/Index";
import classNames from "classnames";
import Head from "./head/Head";
import Sidebar from "./sidebar/Sidebar";
import Header from "./header/Header";
import SessionHandler from "../SessionHandler";



const Layout = () => {
  //Sidebar
  const [mobileView, setMobileView] = useState();
  const [visibility, setVisibility] = useState(false);
  const [themeState] = useState({
    main: "default",
    sidebar: "white",
    header: "white",
    skin: "light",
  });

  useEffect(() => {
    document.body.className = `nk-body bg-lighter npc-default has-sidebar no-touch nk-nio-theme ${
      themeState.skin === "dark" ? "dark-mode" : ""
    }`;
  }, [themeState.skin]);

  useEffect(() => {
    viewChange();
  }, []);

  // Stops scrolling on overlay
  useLayoutEffect(() => {
    if (visibility) {
      document.body.style.overflow = "hidden";
      document.body.style.height = "100%";
    }
    if (!visibility) {
      document.body.style.overflow = "auto";
      document.body.style.height = "auto";
    }
  }, [visibility]);

  // function to toggle sidebar
  const toggleSidebar = (e) => {
    e.preventDefault();
    if (visibility === false) {
      setVisibility(true);
    } else {
      setVisibility(false);
    }
  };

  // function to change the design view under 1200 px
  const viewChange = () => {
    if (window.innerWidth < 1200) {
      setMobileView(true);
    } else {
      setMobileView(false);
      setVisibility(false);
    }
  };
  window.addEventListener("load", viewChange);
  window.addEventListener("resize", viewChange);

  const sidebarClass = classNames({
    "nk-sidebar-mobile": mobileView,
    "nk-sidebar-active": visibility && mobileView,
  });

  // const NAVIGATION = [
  //   {
  //     kind: 'header',
  //     title: 'Main items',
  //   },
  //   {
  //     segment: 'dashboard',
  //     title: 'Dashboard',
  //     icon: <LayoutDashboardIcon />,
  //   },
  //   {
  //     segment: 'items',
  //     title: 'Items',
  //     icon: <ListIcon />,
  //   },
  //   {
  //     segment: 'my-auctions',
  //     title: 'Timed Auctions',
  //     icon: <Hammer />,
  //   },
  //   {
  //     segment: 'auction-groups',
  //     title: 'Live Auctions',
  //     icon: <LiveTv />,
  //   },
  //   {
  //     segment: 'orders',
  //     title: 'Orders',
  //     icon: <ShoppingCartIcon />,
  //   },
  //   {
  //     segment: 'payments',
  //     title: 'Payments',
  //     icon: <PaymentsOutlined />,
  //   },
  //   {
  //     kind: 'divider',
  //   },
  //   {
  //     kind: 'header',
  //     title: 'Analytics',
  //   },
  //   {
  //     segment: 'reports',
  //     title: 'Reports',
  //     // icon: <BarChartIcon />,
  //     children: [
  //       {
  //         segment: 'sales',
  //         title: 'Sales',
  //         // icon: <DescriptionIcon />,
  //       },
  //       {
  //         segment: 'traffic',
  //         title: 'Traffic',
  //         // icon: <DescriptionIcon />,
  //       },
  //     ],
  //   },
  //   {
  //     segment: 'integrations',
  //     title: 'Integrations',
  //     // icon: <LayersIcon />,
  //   },
  // ];

  // const windo = props.window;
  // const navigator =useNavigate()
  
  // const [pathname, setPathname] = React.useState('/dashboard');
  // const navigate = React.useCallback(
  //   (path) => {
  //     setPathname(path)
  //     return navigator(path);
  //   },
    
  //   [],
  // );
  // const router = React.useMemo(() => {
  //   return {
  //     pathname,
  //     searchParams: new URLSearchParams(),
  //     navigate,
  //   };
  // }, [pathname]);


  return (
  //   <React.Fragment>
  //     <AppProvider navigation={NAVIGATION} branding={{
  //   logo: <Logo/>,
  //   title:''
  // }} theme={theme} router={router}window={windo}>
  //       <DashboardLayout >
  //       <Pages/>
  //       </DashboardLayout>
  //     </AppProvider>
  //   </React.Fragment>
    <React.Fragment>
      <Head title="Loading" />
      <div className="nk-app-root">
        <div className="nk-main">
          <Sidebar
            sidebarToggle={toggleSidebar}
            fixed
            mobileView={mobileView}
            theme={themeState.sidebar}
            className={sidebarClass}
          />
          {visibility && mobileView && <div className="nk-sidebar-overlay" onClick={toggleSidebar}></div>}
          <div className="nk-wrap">
            <Header sidebarToggle={toggleSidebar} fixed setVisibility={setVisibility} theme={themeState.header} />
            <SessionHandler />
            <Pages />
            {/* <Footer /> */}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
export default Layout;
