import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { axios } from '../../../utils/axios';
import { debounce } from 'lodash';
import { setAuctionDetails } from './auctionDetailsSlice';
import { auctionStatus } from '../../../utils/Utils';
import { db } from '../../../utils/firebase';
import { doc, onSnapshot } from 'firebase/firestore';

const getCurrentBidAmount = (auction) => {
  if (!auction) return 0;
  return (
    auction.current_bid?.amount ??
    auction.current_price ??
    auction.item?.auction_start_price ??
    auction.item?.auction_starting_price ??
    0
  );
};

const initialState = {
  data: null,
  loading: false,
  error: null,
  auctions: [],
  bids: [],
  currentBid: null,
  completedAuctions: [],
  totalCount: 0,
  perPage: 10,
  fireStoreUnSubscribe: null,
  auctionGroupUnSubscribe: null,
  fetchAuctionsStatus: 'init',
  fetchAuctionsError: null,
  startLiveStatus: 'init',
  startLiveError: null,
  nextLiveStatus: 'init',
  nextLiveError: null,
  extendAuctionStatus: 'init',
  extendAuctionError: null,
  completeAuctionStatus: 'init',
  completeAuctionError: null,
  getSuccessfulBidsStatus: 'init',
  getSuccessfulBidsError: null,
  successfulBids: [],
  selectAuctionWinnerStatus: 'init',
  selectAuctionWinnerError: null,
  updateAuctionsOrderStatus: 'init',
  updateAuctionsOrderError: null,
  placeBidStatus: 'init',
  placeBidError: null
};
const fetchAuctionGroupDetails = createAsyncThunk(
  'auction-groups',
  (data, { dispatch }) => {
    let url = `/device/api/v1/auction-group/${data}`;
    return axios.get(url, data).then((response) => {
      if (response.data?.data?.current_auction) {
        dispatch(setAuctionDetails(response.data?.data?.current_auction));
      }
      return response.data;
    });
  }
);
const startLiveAuction = createAsyncThunk(
  'auction-groups/start',
  (data, { dispatch }) => {
    let url = `/device/api/v1/auction-group/start/${data}`;
    return axios.post(url).then((response) => {
      dispatch(setAuctionDetails(response.data?.data?.current_auction));
      return response.data;
    });
  }
);

const nextLiveAuction = createAsyncThunk(
  'auction-groups/next',
  async (data, { dispatch }) => {
    let url = `/device/api/v1/auction-group/nextAuction/${data}`;
    const response = await axios.post(
      `/device/api/v1/auction-group/nextAuction/${data}`
    );
    const currentAuction = response.data?.data?.current_auction;

    if (currentAuction) {
      dispatch(setAuctionDetails(currentAuction));
      // Fetch bids for the new auction
      dispatch(
        fetchAuctionBids({
          query: { auction_id: currentAuction.id },
          auctionGroupId: response.data?.data?.id,
          options: { sort: { createdAt: -1 }, page: 1, limit: 5 }
        })
      );
    }

    dispatch(popCompletedAuction(data));
    return response.data;
  }
);

const fetchAuctionsInAuctionGroups = createAsyncThunk('auctions', (data) => {
  let url = `/device/api/v1/auction-group/auctions/${data}?limit=500`;
  return axios.get(url, data).then((response) => {
    return response.data;
  });
});
const extendAuction = createAsyncThunk(
  'extendAuction',
  (data, { dispatch }) => {
    let url = `/device/api/v1/auction-group/extendAuction`;
    return axios.post(url, data).then((response) => {
      dispatch(setAuctionDetails(response?.data?.data));
      return response.data;
    });
  }
);
const completeAuction = createAsyncThunk(
  'completeAuction',
  (data, { dispatch }) => {
    let url = `/device/api/v1/auction-group/completeAuction`;
    return axios.post(url, data).then((response) => {
      dispatch(setAuctionDetails(response?.data?.data));
      return response.data;
    });
  }
);
const getSuccessfulBids = createAsyncThunk(
  'getSuccessfulBids',
  (id, { dispatch }) => {
    let url = `/device/api/v1/bid/successfull/${id}`;
    return axios.get(url).then((response) => {
      return response.data;
    });
  }
);
const selectAuctionWinner = createAsyncThunk(
  'auctions/selectWinner',
  (data, { dispatch }) => {
    const { body ,id} = data;
    let url = '/device/api/v1/auction-group/select-winner';
    return axios.post(url, body).then((response) => {
      dispatch(setAuctionDetails(response.data?.data?.current_auction));
      dispatch(popCompletedAuction(id));
      return response.data;
    });
  }
);
const updateAuctionsOrder = createAsyncThunk(
  'auctions/update-auctions-order',
  (data, { dispatch }) => {
    const { id, body } = data;
    let url = `/device/api/v1/auction-group/update-auctions-order/${id}`;
    return axios.post(url, body).then((response) => {
      return response.data;
    });
  }
);

const fetchAuctionBids = createAsyncThunk(
  'auctions-group/bids',
  (data, { dispatch }) => {
    let url = `/device/api/v1/bid/list`;
    return axios.post(url, data).then((response) => {
      dispatch(
        connectToFirestore({
          currentAuctionId: data.query.auction_id,
          auctionGroupId: data.auctionGroupId
        })
      );
      return response?.data?.data;
    });
  }
);

const connectToFirestore = createAsyncThunk(
  'auctions-group/bids',
  (data, { dispatch }) => {
    const { auctionGroupId, currentAuctionId } = data;
    const docRef = doc(
      db,
      'auction-groups',
      auctionGroupId,
      'auctions',
      currentAuctionId
    );

    const unsubscribe = onSnapshot(docRef, (snapshot) => {
      if (snapshot.exists()) {
        const docData = snapshot.data();
        dispatch(onNewBid(docData.current_bid));
        if (docData.current_bid?.extended) {
          dispatch(onTimeExtended(docData.current_bid.duration));
        }
      } else {
        console.log('Document does not exist.');
      }
    });
    return unsubscribe;
  }
);
const connectToAuctionGroupEvents = createAsyncThunk(
  'auctions-group/events',
  (data, { dispatch, getState }) => {
    const { auctionGroupId } = data;
    const docRef = doc(db, 'auction-groups', auctionGroupId);

    let state = getState().auctionGroupDetails;
    if (state.auctionGroupUnSubscribe) {
      const auctionGroupUnSubscribe = state.auctionGroupUnSubscribe;
      if (typeof auctionGroupUnSubscribe === 'function') {
        auctionGroupUnSubscribe();
      }
      state.auctionGroupUnSubscribe = null;
    }

    const unsubscribe = onSnapshot(docRef, (snapshot) => {
      if (snapshot.exists()) {
        const docData = snapshot.data();
        dispatch(onNewEvent(docData));
        const oldAuctionId = state.data?.current_auction?.id;
        const newAuctionId = docData?.current_auction?.id;
        if (oldAuctionId !== newAuctionId) {
          dispatch(fetchAuctionBids());
        }
      } else {
        console.log('Document does not exist.');
      }
    });
    return unsubscribe;
  }
);

const placeBidGroup = createAsyncThunk(
  'auctions/placeBidGroup',
  (amount, { getState }) => {
    const state = getState().auctionGroupDetails;
    const authUser = getState().auth.loggedInUser;

    const auction = state.data?.current_auction;

    const data = {
      user_id: authUser.id,
      auction_id: auction.id,
      increment: Number(amount)
    };
    return axios
      .post(`/device/api/v1/bid/create/`, data)
      .then((response) => response.data);
  }
);

const auctionGroupsSlice = createSlice({
  name: 'auctions',
  initialState,
  reducers: {
    popCompletedAuction: (state, action) => {
      // First find the auction to be completed
      const completedAuction = state.auctions.find(e => e.id === action.payload);
      
      if (completedAuction) {
        // Remove it from active auctions
        state.auctions = state.auctions.filter(e => e.id !== action.payload);
        // Add it to completed auctions
        state.completedAuctions.push(completedAuction);
      }
    },
    resetSuccessfulBids: (state) => {
      state.successfulBids = [];
      state.getSuccessfulBidsStatus = 'init';
      state.getSuccessfulBidsError = null;
    },
    resetPlacedBids: (state) => {
      state.placeBidStatus = 'init';
      state.placeBidError = null;
    },
    resetSelectWinner: (state) => {
      state.selectAuctionWinnerStatus = 'init';
      state.selectAuctionWinnerError = null;
    },
    resetStartAuction: (state) => {
      state.startLiveStatus = 'init';
      state.startLiveError = null;
    },
    resetUpdateAuctionsOrder: (state) => {
      state.updateAuctionsOrderStatus = 'init';
      state.updateAuctionsOrderError = null;
    },
    onTimeExtended: (state, action) => {
      state.data.current_auction.duration = action.payload;
    },
    setAuctionsInAuctionsGroup: (state, action) => {
      state.auctions = action.payload;
    },
    disconnectFromFirestore: (state) => {
      if (state.fireStoreUnSubscribe) {
        const fireStoreUnSubscribe = state.fireStoreUnSubscribe;
        if (typeof fireStoreUnSubscribe === 'function') {
          fireStoreUnSubscribe();
        }
        state.fireStoreUnSubscribe = null;
      }
    },
    onNewBid: (state, action) => {
      if (state.bids?.[0]?.id !== action.payload?.id) {
        state.bids.unshift(action.payload);
        state.data.current_auction.current_price = action.payload?.amount;
        state.data.current_auction.current_bid = action.payload;
        state.currentBid = action.payload?.amount;
      }
    },
    onNewEvent: (state, action) => {
      const newData = action.payload;
      const oldAuctionId = state.data?.current_auction?.id;
      const newAuctionId = newData?.current_auction?.id;

      // Update state data
      state.data = newData;

      // Reset currentBid when auction changes
      if (oldAuctionId !== newAuctionId) {
        state.currentBid = getCurrentBidAmount(newData?.current_auction);
        state.bids = []; // Reset bids for new auction
      }
     
    },
    onCancelAuction:(state, action) =>{
      state.data.status = 4;
    },
    disconnectFromAuctionGroupEvents: (state) => {
      if (state.auctionGroupUnSubscribe) {
        const auctionGroupUnSubscribe = state.auctionGroupUnSubscribe;
        if (typeof auctionGroupUnSubscribe === 'function') {
          auctionGroupUnSubscribe();
        }
        state.auctionGroupUnSubscribe = null;
      }
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAuctionGroupDetails.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.data = null;
    });
    builder.addCase(fetchAuctionGroupDetails.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload?.data;
      state.currentBid =
        action.payload?.current_auction?.current_price ??
        action.payload?.current_auction?.current_bid?.amount ??
        action.payload?.current_auction?.item?.auction_start_price ??
        0;
    });
    builder.addCase(fetchAuctionGroupDetails.rejected, (state, action) => {
      state.loading = false;
      state.data = null;
      state.error = action.error.message;
    });
    builder.addCase(fetchAuctionsInAuctionGroups.pending, (state) => {
      state.fetchAuctionsStatus = 'loading';
      state.fetchAuctionsError = null;
      state.auctions = null;
    });
    builder.addCase(fetchAuctionsInAuctionGroups.fulfilled, (state, action) => {
      state.fetchAuctionsStatus = 'success';
      state.auctions = action.payload?.data?.data?.filter(
        (e) =>
          ![
            auctionStatus.COMPLETED,
            auctionStatus.SOLD,
            auctionStatus.UNSOLD
          ].includes(e.status)
      );
      state.completedAuctions = action.payload?.data?.data?.filter((e) =>
        [
          auctionStatus.COMPLETED,
          auctionStatus.SOLD,
          auctionStatus.UNSOLD
        ].includes(e.status)
      );
      state.totalCount = action.payload?.data?.paginator?.itemCount;
      state.perPage = action.payload?.data?.paginator?.perPage;
    });
    builder.addCase(fetchAuctionsInAuctionGroups.rejected, (state, action) => {
      state.fetchAuctionsStatus = 'error';
      state.auctions = null;
      state.fetchAuctionsError = action.error.message;
    });
    builder.addCase(startLiveAuction.pending, (state) => {
      state.startLiveStatus = 'loading';
      state.startLiveError = null;
    });
    builder.addCase(startLiveAuction.fulfilled, (state, action) => {
      state.startLiveStatus = 'success';
      state.data = action.payload.data;
    });
    builder.addCase(startLiveAuction.rejected, (state, action) => {
      state.startLiveStatus = 'error';
      state.startLiveError = action.error.message;
    });
    builder.addCase(nextLiveAuction.pending, (state) => {
      state.nextLiveStatus = 'loading';
      state.nextLiveError = null;
    });
    builder.addCase(nextLiveAuction.fulfilled, (state, action) => {
      state.nextLiveStatus = 'success';
      state.data = action.payload?.data;
    });
    builder.addCase(nextLiveAuction.rejected, (state, action) => {
      state.nextLiveStatus = 'error';
      state.nextLiveError = action.error.message;
    });
    builder.addCase(extendAuction.pending, (state) => {
      state.extendAuctionStatus = 'loading';
      state.extendAuctionError = null;
    });
    builder.addCase(extendAuction.fulfilled, (state, action) => {
      state.extendAuctionStatus = 'success';
      state.data.current_auction = action.payload?.data;
    });
    builder.addCase(extendAuction.rejected, (state, action) => {
      state.extendAuctionStatus = 'error';
      state.extendAuctionError = action.error.message;
    });
    builder.addCase(completeAuction.pending, (state) => {
      state.completeAuctionStatus = 'loading';
      state.completeAuctionError = null;
    });
    builder.addCase(completeAuction.fulfilled, (state, action) => {
      state.completeAuctionStatus = 'success';
      state.data.current_auction = action.payload?.data;
    });
    builder.addCase(completeAuction.rejected, (state, action) => {
      state.completeAuctionStatus = 'error';
      state.completeAuctionError = action.error.message;
    });
    builder.addCase(getSuccessfulBids.pending, (state) => {
      state.getSuccessfulBidsStatus = 'loading';
      state.getSuccessfulBidsError = null;
    });
    builder.addCase(getSuccessfulBids.fulfilled, (state, action) => {
      state.getSuccessfulBidsStatus = 'success';
      state.successfulBids = action.payload?.data;
    });
    builder.addCase(getSuccessfulBids.rejected, (state, action) => {
      state.getSuccessfulBidsStatus = 'error';
      state.getSuccessfulBidsError = action.error.message;
    });
    builder.addCase(selectAuctionWinner.pending, (state) => {
      state.selectAuctionWinnerStatus = 'loading';
      state.selectAuctionWinnerError = null;
    });
    builder.addCase(selectAuctionWinner.fulfilled, (state, action) => {
      state.selectAuctionWinnerStatus = 'success';
      state.data = action.payload?.data;
    });
    builder.addCase(selectAuctionWinner.rejected, (state, action) => {
      state.selectAuctionWinnerStatus = 'error';
      state.selectAuctionWinnerError = action.error.message;
    });
    builder.addCase(updateAuctionsOrder.pending, (state) => {
      state.updateAuctionsOrderStatus = 'loading';
      state.updateAuctionsOrderError = null;
    });
    builder.addCase(updateAuctionsOrder.fulfilled, (state, action) => {
      state.updateAuctionsOrderStatus = 'success';
      state.auctions = action.payload?.data;
    });
    builder.addCase(updateAuctionsOrder.rejected, (state, action) => {
      state.updateAuctionsOrderStatus = 'error';
      state.updateAuctionsOrderError = action.error.message;
    });
    // fetch bids state
    builder.addCase(fetchAuctionBids.pending, (state) => {});
    builder.addCase(fetchAuctionBids.fulfilled, (state, action) => {
      state.bids = action.payload?.data ?? [];
      state.currentBid =
        action.payload?.data?.[0]?.amount ??
        state.data.current_auction?.current_price;
    });
    builder.addCase(fetchAuctionBids.rejected, (state, action) => {});
    // place bids
    builder.addCase(placeBidGroup.pending, (state) => {
      state.placeBidStatus = 'loading';
    });
    builder.addCase(placeBidGroup.fulfilled, (state, action) => {
      state.placeBidStatus = 'success';
    });
    builder.addCase(placeBidGroup.rejected, (state, action) => {
      // console.log(action.error.message);
      state.placeBidStatus = 'error';
      state.placeBidError = action.error.message;
    });
    // auction group listener
    builder.addCase(connectToAuctionGroupEvents.fulfilled, (state, action) => {
      state.auctionGroupUnSubscribe = action.payload;
    });
  }
});

const debounced = debounce(
  ({ ...arg }, dispatch) => dispatch(fetchAuctionsInAuctionGroups({ ...arg })),
  500
);
const debouncedFetchAuctionsInAuctionGroups =
  ({ ...args }) =>
  (dispatch) =>
    debounced({ ...args }, dispatch);

export default auctionGroupsSlice.reducer;
export const {
  popCompletedAuction,
  resetSuccessfulBids,
  resetStartAuction,
  resetUpdateAuctionsOrder,
  onNewBid,
  disconnectFromFirestore,
  onTimeExtended,
  onNewEvent,
  disconnectFromAuctionGroupEvents,
  resetPlacedBids,
  resetSelectWinner,
  setAuctionsInAuctionsGroup,
  onCancelAuction
} = auctionGroupsSlice.actions;
export {
  fetchAuctionGroupDetails,
  fetchAuctionsInAuctionGroups,
  debouncedFetchAuctionsInAuctionGroups,
  startLiveAuction,
  nextLiveAuction,
  extendAuction,
  completeAuction,
  getSuccessfulBids,
  selectAuctionWinner,
  updateAuctionsOrder,
  fetchAuctionBids,
  connectToFirestore,
  placeBidGroup,
  connectToAuctionGroupEvents
};
