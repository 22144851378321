import React from "react";
// import LogoLight2x from "../../images/albahomes-logo.png";
import LogoDark2x from "../../images/Ravo-logo.png";
import LogoSmall from "../../images/small.png";
import { Link } from "react-router-dom";

const Logo = () => {
  return (
    <Link to={`/`} className="logo-link">
      {/* <img className="logo-light logo-img" src={ LogoLight2x } alt="logo" /> */}
      <img className="logo-dark logo-img" src={LogoDark2x} alt="logo" style={{ width: "10rem" }}/>
    </Link>
  );
};

export default Logo;
