import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Box,
  Typography,
  IconButton
} from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';
import { useTheme } from '@mui/material/styles';
import { sessionExpiredSubject } from './utils/axios';


const SessionHandler = () => {
  const [showDialog, setShowDialog] = useState(false);
  const [countdown, setCountdown] = useState(30);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();

  const handleLogout = () => {
    dispatch({ type: 'auth/logout' });
    localStorage.clear();
    navigate('/login');
  };

  useEffect(() => {
    const unsubscribe = sessionExpiredSubject.subscribe(() => {
      setShowDialog(true);
      
      const timer = setInterval(() => {
        setCountdown((prev) => {
          if (prev <= 1) {
            clearInterval(timer);
            handleLogout();
            return 0;
          }
          return prev - 1;
        });
      }, 1000);

      return () => {
        clearInterval(timer);
      };
    });

    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <Dialog
      open={showDialog}
      maxWidth="sm"
      fullWidth
      aria-labelledby="session-expired-dialog-title"
      aria-describedby="session-expired-dialog-description"
      PaperProps={{
        sx: {
          borderRadius: 2,
          p: 1
        }
      }}
    >
      <DialogTitle
        id="session-expired-dialog-title"
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 1,
          color: theme.palette.warning.main
        }}
      >
        <LogoutIcon sx={{ fontSize: 24 }} />
        <Typography variant="h6" component="span">
          Session Expired
        </Typography>
      </DialogTitle>
      
      <DialogContent>
        <DialogContentText id="session-expired-dialog-description">
          <Box sx={{ mb: 2 }}>
            Your session has expired. You will be automatically logged out in {countdown} seconds.
          </Box>
          <Box>
            Please save any unsaved changes before logging out.
          </Box>
        </DialogContentText>
      </DialogContent>
      
      <DialogActions sx={{ p: 2, pt: 0 }}>
        <Button
          onClick={handleLogout}
          variant="contained"
          color="warning"
          startIcon={<LogoutIcon />}
          sx={{
            textTransform: 'none',
            px: 3,
            py: 1
          }}
        >
          Log out now
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SessionHandler;