import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import {
  fetchOrderDetails,
  resetOrderDetailSlice
} from '../../redux/features/ordersSlice/orderDetailSlice';
import {
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  CircularProgress,
  Container,
  Divider,
  Grid2,
  IconButton,
  Paper,
  Stack,
  Typography,
  useTheme
} from '@mui/material';
import {
  AccountCircle,
  Payment,
  LocalShipping,
  Info,
  Timeline,
  DirectionsCar
} from '@mui/icons-material';
import Content from '../../layout/content/Content';
import { Image } from '../../components/Component';
import UpdateUserInfo from './components/UpdateUserInfo';
import AddTransaction from './components/AddTransaction';
import MarkAsSold from './components/MarkAsSold';
import CancelOrder from './components/CancelOrder';
import DispatchVehicle from './components/DispatchVehicle';
import { toast } from 'react-toastify';
import { Hand } from 'lucide-react';
import { Badge } from 'reactstrap';

const StatusChip = ({ status }) => {
  const theme = useTheme();
  const colors = {
    PENDING: {
      bg: theme.palette.warning.light,
      color: theme.palette.warning.dark
    },
    COMPLETED: {
      bg: theme.palette.success.light,
      color: theme.palette.success.dark
    },
    CANCELLED: {
      bg: theme.palette.error.light,
      color: theme.palette.error.dark
    }
  };

  const chipStyle = colors[status] || {
    bg: theme.palette.grey[200],
    color: theme.palette.grey[700]
  };

  return (
    <Chip
      label={status}
      sx={{
        backgroundColor: chipStyle.bg,
        color: chipStyle.color,
        fontWeight: 'medium'
      }}
    />
  );
};

const InfoSection = ({ title, icon: Icon, children }) => (
  <Card elevation={1} sx={{ height: '100%', width: '100%' }}>
    <CardContent>
      <Stack spacing={2}>
        <Stack direction="row" spacing={1} alignItems="center">
          <Icon color="primary" />
          <Typography variant="h6">{title}</Typography>
        </Stack>
        <Divider />
        {children}
      </Stack>
    </CardContent>
  </Card>
);

const ItemCard = ({ item, onClick }) => {
  if (!item) return null;

  return (
    <Paper
      elevation={1}
      sx={{
        cursor: 'pointer',
        '&:hover': {
          elevation: 4,
          transform: 'translateY(-2px)',
          transition: 'all 0.3s ease-in-out'
        }
      }}
      onClick={onClick}
    >
      <Box p={2}>
        <Grid2 container spacing={2}>
          <Grid2 item xs={12} sm={4}>
            <Image
              image={item.title_image || '/api/placeholder/400/300'}
              alt={`${item.make} ${item.model}`}
              width={100}
              size={100}
            />
          </Grid2>
          <Grid2 item xs={12} sm={8}>
            <Stack spacing={2}>
              <Typography variant="h6">
                {item.make} {item.model} ({item.year})
              </Typography>
              <Stack direction="row" spacing={3}>
                <Stack direction="row" spacing={1} alignItems="center">
                  <DirectionsCar fontSize="small" />
                  <Typography variant="body2">
                    {item.cylinder || 4} CYL
                  </Typography>
                </Stack>
                <Stack direction="row" spacing={1} alignItems="center">
                  <Timeline fontSize="small" />
                  <Typography variant="body2">
                    {item.Mileage || 0} Km
                  </Typography>
                </Stack>
              </Stack>
              <Stack direction="row" spacing={1} alignItems="center">
                <Typography variant="body2" color="text.secondary">
                  Starting Price:
                </Typography>
                <Typography
                  variant="subtitle1"
                  color="primary"
                  fontWeight="bold"
                >
                  AED {item.auction_starting_price}
                </Typography>
              </Stack>
            </Stack>
          </Grid2>
        </Grid2>
      </Box>
    </Paper>
  );
};

const TransactionCard = ({ transaction }) => (
  <Card elevation={1}>
    <CardContent>
      <Grid2 container spacing={2}>
        <Grid2 item size={12}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="subtitle1" fontWeight="bold">
              #{transaction.invoice_number}
            </Typography>
            <StatusChip status={transaction.status} />
          </Stack>
        </Grid2>
        <Grid2 item size={12}>
          <Divider />
        </Grid2>
        <Grid2 item size={6}>
          <Stack spacing={1}>
            <Typography variant="body2" color="text.secondary">
              Payment For
            </Typography>
            <Typography variant="body1">{transaction.payment_for}</Typography>
          </Stack>
        </Grid2>
        <Grid2 item size={6}>
          <Stack spacing={1}>
            <Typography variant="body2" color="text.secondary">
              Amount
            </Typography>
            <Typography variant="body1">
              ${transaction.amount.toFixed(2)}
            </Typography>
          </Stack>
        </Grid2>
        <Grid2 item size={6}>
          <Stack spacing={1}>
            <Typography variant="body2" color="text.secondary">
              Payment Type
            </Typography>
            <Typography variant="body1">{transaction.payment_type}</Typography>
          </Stack>
        </Grid2>
        <Grid2 item size={6}>
          <Stack spacing={1}>
            <Typography variant="body2" color="text.secondary">
              Payment Time
            </Typography>
            <Typography variant="body1">
              {new Date(transaction.payment_time).toLocaleString()}
            </Typography>
          </Stack>
        </Grid2>
      </Grid2>
    </CardContent>
  </Card>
);

export default function OrderDetailsPage() {
  const { orderId } = useParams();
  const { order } = useSelector((state) => state.orderDetails);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  React.useEffect(() => {
    if (orderId) {
      dispatch(fetchOrderDetails(orderId));
    }
    return () => {
      dispatch(resetOrderDetailSlice());
    };
  }, [orderId, dispatch]);

  const [activeMenuDialog, setActiveMenuDialog] = useState(null);

  const handleOpenDialog = (type, order) => {
    console.log('Selected order :: ', order);
    setActiveMenuDialog(type);
  };

  // Function to close the dialog
  const handleCloseDialog = (e) => {
    setActiveMenuDialog(null);
  };

  const menuItems = {
    UPDATE_USER: 'UPDATE_USER',
    TRANSACTION: 'TRANSACTION',
    SOLD: 'SOLD',
    CANCEL: 'CANCEL',
    DISPATCH: 'DISPATCH'
  };

  const addTransactionStatus = useSelector(
    (state) => state.addTransaction,
    (a, b) => a.addTransaction?.orderStatus !== b.addTransaction?.orderStatus
  );
  const cancelOrderStatus = useSelector(
    (state) => state.cancelOrder,
    (a, b) => a.addTransaction?.orderStatus !== b.addTransaction?.orderStatus
  );
  const dispatchVehicleStatus = useSelector(
    (state) => state.dispatchVehicle,
    (a, b) => a.dispatchVehicle?.orderStatus !== b.dispatchVehicle?.orderStatus
  );
  const markSoldStatus = useSelector(
    (state) => state.markAsSold,
    (a, b) => a.markAsSold?.orderStatus !== b.markAsSold?.orderStatus
  );
  const updateUserOrderStatus = useSelector(
    (state) => state.updateUserOrder,
    (a, b) => a.updateUserOrder?.orderStatus !== b.updateUserOrder?.orderStatus
  );

  useEffect(() => {
    console.log('Effect triggered :: ', addTransactionStatus);
    if (addTransactionStatus.orderStatus === 'success') {
      toast.success('Transaction added successfully!');
      dispatch(fetchOrderDetails(orderId));
    } else if (addTransactionStatus.orderStatus === 'error') {
      toast.error(addTransactionStatus.error ?? 'Failed to add transaction!');
    }
  }, [addTransactionStatus]);

  useEffect(() => {
    console.log('Effect triggered :: ', cancelOrderStatus);
    if (cancelOrderStatus.orderStatus === 'success') {
      toast.success('Order cancellation iniated successfully!');
      dispatch(fetchOrderDetails(orderId));
    } else if (cancelOrderStatus.orderStatus === 'error') {
      toast.error(
        cancelOrderStatus.error ??
          'Failed to cancel order.Please contact Ravo support center!'
      );
    }
  }, [cancelOrderStatus]);

  useEffect(() => {
    console.log('Effect triggered :: ', dispatchVehicleStatus);
    if (dispatchVehicleStatus.orderStatus === 'success') {
      toast.success('Vehicle marked as delivered!');
      dispatch(fetchOrderDetails(orderId));
    } else if (dispatchVehicleStatus.orderStatus === 'error') {
      toast.error(
        dispatchVehicleStatus.error ?? 'Failed to mark vehicle as delivered!'
      );
    }
  }, [dispatchVehicleStatus]);

  useEffect(() => {
    console.log('Effect triggered :: ', markSoldStatus);
    if (markSoldStatus.orderStatus === 'success') {
      toast.success('Vehicle marked as SOLD!');
      dispatch(fetchOrderDetails(orderId));
    } else if (markSoldStatus.orderStatus === 'error') {
      toast.error(markSoldStatus.error ?? 'Failed to mark vehicle as SOLD!');
    }
  }, [markSoldStatus]);

  useEffect(() => {
    console.log('Effect triggered :: ', updateUserOrderStatus);
    if (updateUserOrderStatus.orderStatus === 'success') {
      toast.success('User details updated successfully');
      dispatch(fetchOrderDetails(orderId));
    } else if (updateUserOrderStatus.orderStatus === 'error') {
      toast.error(updateUserOrderStatus.error ?? 'Failed to update user!!');
    }
  }, [updateUserOrderStatus]);

  if (!order) {
    return (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        minHeight="100vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Content>
      <Container maxWidth="xl">
        <Box py={4}>
          <Stack spacing={4}>
            {/* Header */}
            <Stack
              direction={{ xs: 'column', md: 'row' }}
              justifyContent="space-between"
              alignItems={{ xs: 'stretch', md: 'center' }}
              spacing={2}
            >
              <Stack spacing={1}>
                <Typography variant="h4">
                  {order?.vehicle?.make} {order?.vehicle?.model} (
                  {order?.vehicle?.year})
                </Typography>
                <Typography variant="subtitle1" color="text.secondary">
                  Order #{order?.order_id}
                </Typography>
              </Stack>
              <Box>
                {order.status !== 'cancelled' && (
                  <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                    <Button
                      variant="contained"
                      color="error"
                      onClick={() => {
                        handleOpenDialog(menuItems.CANCEL);
                      }}
                    >
                      Cancel Order
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        handleOpenDialog(menuItems.TRANSACTION);
                      }}
                    >
                      Add Payment
                    </Button>
                    <Button
                      variant="contained"
                      color="success"
                      onClick={() => {
                        handleOpenDialog(menuItems.SOLD);
                      }}
                    >
                      Mark as Sold
                    </Button>
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() => {
                        handleOpenDialog(menuItems.DISPATCH);
                      }}
                    >
                      Mark as Delivered
                    </Button>
                  </Stack>
                )}
              </Box>
            </Stack>
            <Grid2 container spacing={3} sx={{ height: '100%' }}>
              {/* User Information */}
              <Grid2 size={6} sx={{ display: 'flex' }}>
                <InfoSection
                  title="User Information"
                  icon={AccountCircle}
                  sx={{ width: '100%' }}
                >
                  <Stack spacing={2}>
                    <Typography variant="body1">
                      Name:{' '}
                      <Link to={`/users/${order?.user?._id}`}>
                        {order?.user?.full_name}{' '}
                      </Link>
                    </Typography>

                    <Typography variant="body1">
                      Phone:{' '}
                      {`${order?.user?.phone?.dial_code}-${order?.user?.phone?.number}`}
                    </Typography>
                    {!order?.user && (
                      <Button
                        variant="contained"
                        size="small"
                        onClick={() => {
                          handleOpenDialog(menuItems.UPDATE_USER);
                        }}
                      >
                        Update User Info
                      </Button>
                    )}
                  </Stack>
                </InfoSection>
              </Grid2>

              {/* Order Type */}
              <Grid2 size={6} sx={{ display: 'flex' }}>
                <InfoSection
                  title="Order Type"
                  icon={Info}
                  sx={{ width: '100%' }}
                >
                  <Typography variant="body1">
                    Sale Type:{' '}
                    {order?.vehicle?.sale_type === 'AUCTION'
                      ? 'Live Auction'
                      : 'Timed Auction'}
                  </Typography>
                  <Stack direction="row">
                    <Typography variant="body1" marginRight={1}>
                      {'Order Status :  '}
                    </Typography>
                    <Badge>{order.status}</Badge>
                  </Stack>
                  {order.status === 'cancelled' && (
                    <>
                      <Typography variant="body1">
                        Cancelled By: {order?.cancelledBy}
                      </Typography>
                      <Typography variant="body1">
                        Cancelled Reason: {order?.cancelReason}
                      </Typography>
                    </>
                  )}
                </InfoSection>
              </Grid2>

              {/* Payment Information */}
              <Grid2 size={6} sx={{ display: 'flex' }}>
                <InfoSection
                  title="Payment Information"
                  icon={Payment}
                  sx={{ width: '100%' }}
                >
                  <Stack spacing={2}>
                    <Typography variant="body1">
                      Price: ${order?.baseAmount?.toLocaleString()}
                    </Typography>
                    <Typography variant="body1">
                      Total Price (inc of VAT 5%): $
                      {order?.amount?? '0'}
                    </Typography>

                    {/* Service Charge Section */}
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      <Typography
                        variant="body1"
                        sx={{ display: 'flex', alignItems: 'center' }}
                      >
                        Service Charge:{' '}
                        <Typography
                          component="span"
                          sx={{
                            textDecoration: 'line-through',
                            color: 'text.secondary',
                            mx: 1
                          }}
                        >
                          AED 500
                        </Typography>
                        <Typography
                          component="span"
                          sx={{ color: 'success.main' }}
                        >
                          (100% off)
                        </Typography>
                      </Typography>
                    </Box>

                    <Typography variant="body1">
                      Paid Payment: $
                      {(
                        order?.amount || 0 - order?.balanceAmount
                      )?.toLocaleString()}
                    </Typography>

                    <Typography variant="body1">
                      Balance: ${order?.balanceAmount?.toLocaleString()}
                    </Typography>
                  </Stack>
                </InfoSection>
              </Grid2>

              {/* Vehicle Details */}
              <Grid2 size={6} sx={{ display: 'flex' }}>
                <InfoSection
                  title="Vehicle Details"
                  icon={DirectionsCar}
                  sx={{ width: '100%' }}
                >
                  <Box onClick={() => navigate(`/items/${order?.vehicle?.id}`)}>
                    <ItemCard item={order?.vehicle} />
                  </Box>
                </InfoSection>
              </Grid2>
            </Grid2>

            {/* Transactions */}
            <InfoSection title="Transactions" icon={LocalShipping}>
              <Stack spacing={2}>
                {order?.transactions?.length > 0
                  ? order?.transactions?.map((transaction, index) => (
                      <TransactionCard key={index} transaction={transaction} />
                    ))
                  : 'No Transactions made'}
              </Stack>
            </InfoSection>
          </Stack>
        </Box>
      </Container>
      {activeMenuDialog === menuItems.UPDATE_USER && order && (
        <UpdateUserInfo
          order={order}
          open={activeMenuDialog === menuItems.UPDATE_USER}
          onClose={handleCloseDialog}
        />
      )}

      {activeMenuDialog === menuItems.TRANSACTION && order && (
        <AddTransaction
          order={order}
          open={activeMenuDialog === menuItems.TRANSACTION}
          onClose={handleCloseDialog}
        />
      )}
      {activeMenuDialog === menuItems.SOLD && (
        <MarkAsSold
          order={order}
          open={activeMenuDialog === menuItems.SOLD}
          onClose={handleCloseDialog}
          onAddTransactionClicked={(e) =>
            handleOpenDialog(menuItems.TRANSACTION, order)
          }
        />
      )}
      {activeMenuDialog === menuItems.CANCEL && (
        <CancelOrder
          order={order}
          open={activeMenuDialog === menuItems.CANCEL}
          onClose={handleCloseDialog}
        />
      )}
      {activeMenuDialog === menuItems.DISPATCH && (
        <DispatchVehicle
          order={order}
          open={activeMenuDialog === menuItems.DISPATCH}
          onClose={handleCloseDialog}
          onAddTransactionClicked={(e) =>
            handleOpenDialog(menuItems.TRANSACTION, order)
          }
        />
      )}
    </Content>
  );
}
