import React, { useEffect, useRef, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  Badge,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Button
} from 'reactstrap';
import { useDebounce } from 'use-debounce';

import TableComponent from '../../components/table/TableComponent';
import DateFilter from '../../components/date-filter/DateFilter';
import {
  fetchCars,
  clearItems,
  setAllSelected,
  setSelected
} from '../../redux/features/itemsSlice/itemsSlice';
import { getColor } from '../../utils/getColor';
import { verticalId } from '../../utils/configEnv';
import Content from '../../layout/content/Content';
import FilterSection from './components/FilterSection';

const AllItems = () => {
  const dispatch = useDispatch();
  const { data, totalCount, loading, selected } = useSelector(
    (state) => state.items
  );
  const { loggedInUser } = useSelector((state) => state.auth);

  // Use a ref to store all filter states
  const [filters, setFilters] = useState({
    searchText: '',
    status: '',
    dateRange: [null, null],
    currentPage: 1,
    sortByField: 'createdAt',
    sortByValue: -1,
    perPage: 10
  });

  const [debouncedSearchText] = useDebounce(filters.searchText, 500);

  // Memoized fetchData function
  const fetchData = useCallback(() => {
    const {
      currentPage,
      sortByField,
      sortByValue,
      dateRange,
      status,
      searchText,
      perPage
    } = filters;
    const data = {
      user: loggedInUser?.id,
      page: currentPage,
      limit: perPage,
      sortBy: sortByField,
      sortOrder: sortByValue === 1 ? 'asc' : 'desc',
      populate: [
        {
          path: 'user_id',
          select: 'full_name'
        }
      ]
    };

    if (status) {
      data.filter = status;
    }
    if (dateRange) {
      data.date = {}
      if(dateRange[0]){
      data.date.from = dateRange[0].toISOString();;
      }
      if(dateRange[1]){
        data.date.to = dateRange[1].toISOString();;
        }
    }
    if (searchText !== '') {
      data.search = searchText.trim();
    }
    dispatch(fetchCars(data));
  }, [filters, loggedInUser?.id, dispatch]);

  // Effect to fetch data when debouncedSearchText changes
  useEffect(() => {
    fetchData();
  }, [fetchData]);

  // Cleanup on component unmount
  useEffect(() => {
    return () => {
      dispatch(clearItems());
    };
  }, [dispatch]);

  const handleSearchChange = (event) => {
    setFilters((prev) => ({
      ...prev,
      searchText: event.target.value,
      currentPage: 1
    }));
  };

  const handleDateFilterChange = (date) => {
    setFilters((prev) => ({ ...prev, dateRange: date, currentPage: 1 }));
  };

  const handleStatusChange = (event) => {
    setFilters((prev) => ({
      ...prev,
      status: event.target.value,
      currentPage: 1
    }));
  };

  const clearFilters = () => {
    setFilters({
      searchText: '',
      dateRange: [null, null],
      status: '',
      currentPage: 1,
      sortByField: 'createdAt',
      sortByValue: -1
    });
  };

  const handlePageChange = (newPage) => {
    setFilters((prev) => ({ ...prev, currentPage: newPage }));
  };

  const handleSortChange = (field, direction) => {
    setFilters((prev) => ({
      ...prev,
      sortByField: field,
      sortByValue: direction,
      currentPage: 1
    }));
  };

  const handleSelectAll = (checked) => {
    dispatch(setAllSelected({ value: checked }));
  };
  const handleSelectRow = (id, checked) => {
    dispatch(setSelected({ id, value: checked }));
  };

  // Column definitions for the table
  const columns = [
    {
      label: 'ID',
      field: 'baseform_id',
      sortable: true,
      render: (item) => <Link to={`/items/${item.id}`}>{item.baseform_id}</Link>
    },
    {
      label: 'Title',
      field: 'title',
      sortable: true,
      render: (item) => (
        <Link
          to={`/items/${item.id}`}
        >{`${item.make} ${item.model} ${item.year}`}</Link>
      )
    },
    {
      label: 'User',
      field: 'user_id',
      sortable: false,
      render: (item) => <span>{item.user_id?.full_name}</span>
    },
    {
      label: 'Status',
      field: 'status',
      sortable: true,
      render: (item) => (
        <Badge color={getColor(item.status)}>{item.status}</Badge>
      )
    },
    {
      label: 'Created At',
      field: 'createdAt',
      sortable: true,
      render: (item) => (
        <span>{new Date(item.createdAt)?.toLocaleString()}</span>
      )
    }
  ];

  return (
    <React.Fragment>
      <Content>
        <FilterSection
          filters={filters}
          handleSearchChange={handleSearchChange}
          handleDateRangeChange={handleDateFilterChange}
          handleStatusChange={handleStatusChange}
          clearFilters={clearFilters}
          selectedRows={selected}
          handleItemsPerPageChange={(v) => {
            console.log(v);
            setFilters((prev) => ({ ...prev, perPage: v.target.value }));
          }}
          itemsPerPage={filters.perPage}
        />

        <TableComponent
          data={data}
          columns={columns}
          totalCount={totalCount}
          perPage={filters.perPage}
          currentPage={filters.currentPage}
          onPageChange={handlePageChange}
          onSortChange={handleSortChange}
          onSelectAll={handleSelectAll}
          onSelectRow={handleSelectRow}
          loading={loading}
          sortByField={filters.sortByField}
          sortByValue={filters.sortByValue}
          selectedRows={selected}
        />
      </Content>
    </React.Fragment>
  );
};

export default AllItems;
