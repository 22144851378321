import {
  AddCard,
  BeenhereOutlined,
  HighlightOff,
  MilitaryTech,
  MoreHorizOutlined,
  Person2Outlined
} from '@mui/icons-material';
import { Divider, IconButton, Menu, MenuItem, Box } from '@mui/material';
import React, { useState } from 'react';

function OptionsMenu({ order, handleOpenDialog }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };
  console.log('anchormenu......................');
  const handleMoreClick = (e) => {
    e.preventDefault();
    setAnchorEl(e.currentTarget);
  };

  const selectItem = (e, type) => {
    handleOpenDialog(type, order);
    handleClose(e);
  };

  const shouldShow = (type) => {
    if (order.status == 'cancelled') {
      return false;
    }
    if (order.status == 'delivered') {
      return false;
    }
    if (type == 'TRANSACTION' || type == 'SOLD' || type == 'UPDATE_USER') {
      return !['fully_paid', 'delivered', 'on_hold'].includes(order.status);
    }

    if (type == 'DISPATCH') {
      return !['delivered'].includes(order.status);
    }
    return true;
  };
  const menuItems = {
    UPDATE_USER: 'UPDATE_USER',
    TRANSACTION: 'TRANSACTION',
    SOLD: 'SOLD',
    CANCEL: 'CANCEL',
    DELIVERED: 'DELIVERED'
  };
  return (
    <>
      {!['cancelled', 'delivered'].includes(order.status) && (
        <div>
          <IconButton onClick={handleMoreClick}>
            {' '}
            <MoreHorizOutlined />{' '}
          </IconButton>

          <Menu
            anchorEl={anchorEl} // Position the menu based on this element
            open={open}
            onClose={handleClose} // Close the menu when an item is clicked or clicked outside
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'left'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left'
            }}
          >
            {!order.user && !order.guestUserDetails && (
              <MenuItem onClick={(e) => selectItem(e, menuItems.UPDATE_USER)}>
                <Person2Outlined /> <Box sx={{ paddingX: 0.5 }} /> Update User
              </MenuItem>
            )}
            {shouldShow(menuItems.TRANSACTION) && (
              <MenuItem onClick={(e) => selectItem(e, menuItems.TRANSACTION)}>
                {' '}
                <AddCard /> <Box sx={{ paddingX: 0.5 }} />
                Add Transactions
              </MenuItem>
            )}
            <Divider />
            {shouldShow(menuItems.SOLD) && (
              <MenuItem onClick={(e) => selectItem(e, menuItems.SOLD)}>
                {' '}
                <MilitaryTech /> <Box sx={{ paddingX: 0.5 }} /> Mark Sold
              </MenuItem>
            )}
            <Divider />
            {shouldShow(menuItems.CANCEL) && (
              <MenuItem onClick={(e) => selectItem(e, menuItems.CANCEL)}>
                {' '}
                <HighlightOff /> <Box sx={{ paddingX: 0.5 }} />
                Cancel Order
              </MenuItem>
            )}
            <Divider />
            {shouldShow(menuItems.DISPATCH) && (
              <MenuItem onClick={(e) => selectItem(e, menuItems.DISPATCH)}>
                {' '}
                <BeenhereOutlined /> <Box sx={{ paddingX: 0.5 }} />
                Vehicle Delivered
              </MenuItem>
            )}
          </Menu>
        </div>
      )}
    </>
  );
}

export default OptionsMenu;
