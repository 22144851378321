import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  Badge,
  Spinner,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
  ButtonGroup,
  Button
} from 'reactstrap';
import { useDebounce } from 'use-debounce';
import {
  fetchAuctionGroups,
  clearAuctions,
  debouncedFetchAuctionGroups,
  setAllSelected,
  setSelected
} from '../../redux/features/auctionSlice/auctionGroupsSlice';
import Content from '../../layout/content/Content';
import ListBlockHeader from '../../components/list-block-header/ListBlockHeader';
import TableComponent from '../../components/table/TableComponent'; // Generic TableComponent
import {
  getAuctionGroupStatus,
  getAuctionStatusBgColor,
  getAuctionStatusDotColor
} from '../../utils/getColor';
import formatDate from '../../utils/dateFormat';
import { auctionStatus } from '../../utils/Utils';
import ReactDatePicker from 'react-datepicker';
import FilterSection from './components/FilterSection';

const AuctionGroups = ({ itemId = null }) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.loggedInUser);
  const { data, totalCount, perPage, loading } = useSelector(
    (state) => state.auctionGroups
  );

  const [filters, setFilters] = useState({
    searchText: '',
    status: '',
    type: '',
    dateRange: [null, null],
    currentPage: 1,
    sortByField: 'createdAt',
    sortByValue: -1
  });

  const [debouncedSearchText] = useDebounce(filters.searchText, 500);
  const verticalGet = localStorage.getItem('verticalGetValue') || '';

  // Fetch auction group data with the current filters
  const fetchData = useCallback(() => {
    const {
      currentPage,
      sortByField,
      sortByValue,
      status,
      type,
      dateRange,
      searchText
    } = filters;
    const requestData = {
      query: {
        user_id: user?.id
      },
      options: {
        page: currentPage,
        limit: perPage,
        sort: sortByValue === 1 ? sortByField : `-${sortByField}`
      }
    };

    if (status) requestData.query.status = status;
    if (type) requestData.query.type = type;
    if (verticalGet) requestData.query.vertical_id = JSON.parse(verticalGet).id;
    if (dateRange) {
      requestData.query.date = {};
      if (dateRange[0]) {
        requestData.query.date.from = dateRange[0].toISOString();
      }
      if (dateRange[1]) {
        requestData.query.date.to = dateRange[1].toISOString();
      }
    }
    if (itemId) requestData.query.baseform_id = itemId;
    if (searchText.trim()) {
      requestData.query.search = searchText.trim();
      dispatch(debouncedFetchAuctionGroups(requestData));
    } else {
      dispatch(fetchAuctionGroups(requestData));
    }
  }, [filters, dispatch, user?.id, perPage, verticalGet, itemId]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    return () => {
      dispatch(clearAuctions());
    };
  }, [dispatch]);

  // Handlers for search, filters, pagination, and sorting
  const handleSearchChange = (e) => {
    setFilters((prev) => ({
      ...prev,
      searchText: e.target.value,
      currentPage: 1
    }));
  };

  const handleDateRangeChange = (range) => {
    setFilters((prev) => ({ ...prev, dateRange: range, currentPage: 1 }));
  };

  // Handle status change
  const handleStatusChange = (e) => {
    setFilters((prev) => ({ ...prev, status: e.target.value, currentPage: 1 }));
  };

  const handlePageChange = (newPage) => {
    setFilters((prev) => ({ ...prev, currentPage: newPage }));
  };

  const handleSortChange = (field, direction) => {
    setFilters((prev) => ({
      ...prev,
      sortByField: field,
      sortByValue: direction,
      currentPage: 1
    }));
  };

  const clearFilters = () => {
    setFilters({
      searchText: '',
      status: undefined,
      type: '',
      dateRange: [null, null],
      currentPage: 1,
      sortByField: 'createdAt',
      sortByValue: -1
    });
    localStorage.removeItem('verticalGetValue');
    localStorage.removeItem('auctionStatusGetValue');
    localStorage.removeItem('auctionTypeGetValue');
  };

  // Columns for the auction group table
  const columns = [
    {
      label: 'ID',
      field: 'serial_number',
      sortable: true,
      render: (item) => (
        <Link to={`/auction-groups/${item.id}`}>{item.serial_number}</Link>
      )
    },
    {
      label: 'Start Time',
      field: 'start_time',
      sortable: true,
      render: (item) => <span>{formatDate(new Date(item.start_time))}</span>
    },
    {
      label: 'Auction Count',
      field: 'total_auctions',
      sortable: true,
      render: (item) => <span>{item.total_auctions}</span>
    },
    {
      label: 'Status',
      field: 'status',
      sortable: true,
      render: (item) => (
        <Badge color={getAuctionStatusBgColor(item.status)}>
          {getAuctionGroupStatus(item.status)}
        </Badge>
      )
    }
  ];

  return (
    <React.Fragment>
      <Content>
        <FilterSection
          handleSearchChange={handleSearchChange}
          handleDateRangeChange={handleDateRangeChange}
          clearFilters={clearFilters}
          handleStatusChange={handleStatusChange}
          filters={filters}
        />

        <TableComponent
          data={data}
          columns={columns}
          totalCount={totalCount}
          perPage={perPage}
          currentPage={filters.currentPage}
          onPageChange={handlePageChange}
          onSortChange={handleSortChange}
          loading={loading}
          sortByField={filters.sortByField}
          sortByValue={filters.sortByValue}
        />
      </Content>
    </React.Fragment>
  );
};

export default AuctionGroups;
