import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axios } from "../../../utils/axios";
import { fetchOrders } from "./ordersListSlice";


const initialState = {
    order: null,
    orderStatus: 'init',
    error: null
};

const addTransactionToPayment = createAsyncThunk('add-transaction', (data, { dispatch }) => {
    const { userId, dealerId, amount, orderId } = data;
    const url = '/device/api/v1/paymenttransaction/create';
    const payload = {
        user_id: userId,
        payment_for: "ORDER_PAYMENT",
        dealer_id: dealerId,
        order_id: orderId,
        amount: amount.toString(),
        status: "SUCCESS",
        payment_time: new Date(),
        isDeleted: false,
        isActive: true
    };
    return axios.post(url, payload).then((response) => {
        console.log("Add transaction data :: ", data.data);
        const isDelivered = data.isDelivered;
        const isFullSettlemet = data.isFullSettlemet;
        if(isDelivered){
             // Mark as fully paid and adjust the amount if there are any pending balance
             dispatch(dispatchVehicle(orderId));
        }
        else if(isFullSettlemet){
            // Mark as fully paid and adjust the amount if there are any pending balance
            dispatch(markAsSold(orderId))
        }else{
            //dispatch(fetchOrders());
        }
        return response.data.data;
    })
});

const updateUserOrder = createAsyncThunk('update-user', (data,{ dispatch }) => {
    const {orderId, userName, userPhone} = data;
    const url = '/device/api/v1/order/updateUser';
    const payload = { "orderId": orderId, "guestUserName": userName, "guestUserPhone": userPhone };
    return axios.patch(url, payload).then((response) => {
         //dispatch(fetchOrders());
        return response.data.data;
    })
});

const approveOrder = createAsyncThunk('approve-order', (orderId,{ dispatch }) => {
    const url = '/device/api/v1/paymenttransaction/create';
    const payload = { "orderId": orderId };
    return axios.patch(url, payload).then((response) => {
        // dispatch(fetchOrders());
        return response.data.data;
    })
});

const markAsSold = createAsyncThunk('mark-sold', (orderId,{ dispatch }) => {
    const url = '/device/api/v1/order/markassold';
    const payload = { "orderId": orderId };
    return axios.patch(url, payload).then((response) => {
        //   dispatch(fetchOrders());
        return response.data.data;
    });
})

const cancelOrder = createAsyncThunk('cancel-order', (data,{ dispatch }) => {
    const url = '/device/api/v1/order/cancel';
    const payload = { "orderId": data.id, "cancelReason": data.cancelReason ,cancelledBy:data.cancelledBy};
    return axios.patch(url, payload).then((response) => {
        // dispatch(fetchOrders());
        return response.data.data;
    });
})


const dispatchVehicle = createAsyncThunk('dispatch-vehicle', (orderId,{dispatch}) => {
    const url = '/device/api/v1/order/dispatchVehicle';
    const payload = { "orderId": orderId };
    return axios.patch(url, payload).then((response) => {
        //  dispatch(fetchOrders());
        return response.data.data;
    });
})



const addTransactionSlice = createSlice({
    name: "add-transaction",
    initialState: initialState,
    reducers: {
        resetOrders: (state) => {
            state = {}
        }
    },
    extraReducers: (builder) => {
        builder.addCase(addTransactionToPayment.pending, (state) => {
            state.orderStatus = "loading";
            state.error = null;
            state.order = null;
        });
        builder.addCase(addTransactionToPayment.fulfilled, (state, action) => {
            state.orderStatus = "success";
            state.error = null;
            state.order = action.payload;
        });
        builder.addCase(addTransactionToPayment.rejected, (state, action) => {
            state.orderStatus = 'error';
            state.order = null;
            state.error = action.error.message;
        });
    }
});

const approveOrderSlice = createSlice({
    name: "approve-order",
    initialState: initialState,
    reducers: {
        resetOrders: (state) => {
            state = {}
        }
    },
    extraReducers: (builder) => {
        builder.addCase(approveOrder.pending, (state) => {
            state.orderStatus = "loading";
            state.error = null;
            state.order = null;
        });
        builder.addCase(approveOrder.fulfilled, (state, action) => {
            state.orderStatus = "success";
            state.error = null;
            state.order = action.payload;
        });
        builder.addCase(approveOrder.rejected, (state, action) => {
            state.orderStatus = 'error';
            state.order = null;
            state.error = action.error.message;
        });
    }
});
const updateUserOrderSlice = createSlice({
    name: "update-user",
    initialState: initialState,
    reducers: {
        resetOrders: (state) => {
            state = {}
        }
    },
    extraReducers: (builder) => {
        builder.addCase(updateUserOrder.pending, (state) => {
            state.orderStatus = "loading";
            state.error = null;
            state.order = null;
        });
        builder.addCase(updateUserOrder.fulfilled, (state, action) => {
            state.orderStatus = "success";
            state.error = null;
            state.order = action.payload;
        });
        builder.addCase(updateUserOrder.rejected, (state, action) => {
            state.orderStatus = 'error';
            state.order = null;
            state.error = action.error.message;
        });
    }
});

const markAsSoldSlice = createSlice({
    name: "mark-sold",
    initialState: initialState,
    reducers: {
        resetOrders: (state) => {
            state = {}
        }
    },
    extraReducers: (builder) => {
        builder.addCase(markAsSold.pending, (state) => {
            state.orderStatus = "loading";
            state.error = null;
            state.order = null;
        });
        builder.addCase(markAsSold.fulfilled, (state, action) => {
            state.orderStatus = "success";
            state.error = null;
            state.order = action.payload;
        });
        builder.addCase(markAsSold.rejected, (state, action) => {
            state.orderStatus = 'error';
            state.order = null;
            state.error = action.error.message;
        });
    }
});

const cancelOrderSlice = createSlice({
    name: "cancel-order",
    initialState: initialState,
    reducers: {
        resetOrders: (state) => {
            state = {}
        }
    },
    extraReducers: (builder) => {
        builder.addCase(cancelOrder.pending, (state) => {
            state.orderStatus = "loading";
            state.error = null;
            state.order = null;
        });
        builder.addCase(cancelOrder.fulfilled, (state, action) => {
            state.orderStatus = "success";
            state.error = null;
            state.order = action.payload;
        });
        builder.addCase(cancelOrder.rejected, (state, action) => {
            state.orderStatus = 'error';
            state.order = null;
            state.error = action.error.message;
        });
    }
});

const dispatchVehicleSlice = createSlice({
    name: "dispatch-vehicle",
    initialState: initialState,
    reducers: {
        resetOrders: (state) => {
            state = {}
        }
    },
    extraReducers: (builder) => {
        console.log("Dispatch reducer triggerd");
        builder.addCase(dispatchVehicle.pending, (state) => {
            state.orderStatus = "loading";
            state.error = null;
            state.order = null;
        });
        builder.addCase(dispatchVehicle.fulfilled, (state, action) => {
            state.orderStatus = "success";
            state.error = null;
            state.order = action.payload;
        });
        builder.addCase(dispatchVehicle.rejected, (state, action) => {
            state.orderStatus = 'error';
            state.order = null;
            state.error = action.error.message;
        });
    }
});


const addTransactionReducer = addTransactionSlice.reducer;
const updateUserOrderReducer = updateUserOrderSlice.reducer;
const approveOrderReducer = approveOrderSlice.reducer;
const markAsSoldReducer = markAsSoldSlice.reducer;
const cancelOrderReducer = cancelOrderSlice.reducer;
const dispatchVehicleReducer = dispatchVehicleSlice.reducer;

export { addTransactionReducer, updateUserOrderReducer, approveOrderReducer, markAsSoldReducer, cancelOrderReducer, dispatchVehicleReducer };

export { addTransactionToPayment, markAsSold, cancelOrder, dispatchVehicle, updateUserOrder, approveOrder };