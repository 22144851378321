import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, } from 'react-router-dom';
import {
  Badge,
} from 'reactstrap';
import {

  setAllSelected,
  setSelected
} from '../../redux/features/directSaleSlice/directSaleSlice';
import Content from '../../layout/content/Content';
import TableComponent from '../../components/table/TableComponent';
import { getOrderStatusColor } from '../../utils/getColor';
import FilterSection from './components/FilterSection';
import { fetchOrders } from '../../redux/features/ordersSlice/ordersListSlice';
import OptionsMenu from './components/OptionsMenu';
import AddTransaction from './components/AddTransaction';
import MarkAsSold from './components/MarkAsSold';
import CancelOrder from './components/CancelOrder';
import DispatchVehicle from './components/DispatchVehicle';
import { toast } from 'react-toastify';
import UpdateUserInfo from './components/UpdateUserInfo';
import { capitalize } from '../../utils/Utils';

const OrdersListPage = () => {
  const dispatch = useDispatch();
  const [filters, setFilters] = useState({
    searchText: '',
    status: '',
    dateRange: [null, null],
    currentPage: 1,
    sortByField: 'createdAt',
    sortByValue: -1,
    vertical: ''
  });

  const { data, totalCount, fetchOrderStatus, perPage, selected } =
    useSelector((state) => state.orders);
  const { loggedInUser: user } = useSelector((state) => state.auth);

  // Fetch data based on filters
  const fetchData = useCallback(() => {
    const {
      currentPage,
      sortByField,
      sortByValue,
      status,
      dateRange,
      searchText
    } = filters;

    const requestData = {
      query: {},
      options: {
        page: currentPage,
        limit: perPage,
        sort: sortByValue === 1 ? sortByField : `-${sortByField}`,
        populate: [{ path: 'user_id', select: 'full_name' }]
      }
    };
    if (status) {
      requestData.query.status = status;
    }
    if (dateRange) {
      requestData.query.date = {};
      if (dateRange[0]) {
        requestData.query.date.from = dateRange[0].toISOString();
      }
      if (dateRange[1]) {
        requestData.query.date.to = dateRange[1].toISOString();
      }
    }
    if (searchText !== '') {
      requestData.query.searchId = searchText.trim();
    }
    dispatch(fetchOrders(requestData));
  }, [filters, dispatch, user?.id, perPage]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);


  // Handle pagination and sorting
  const handlePageChange = (newPage) =>
    setFilters((prev) => ({ ...prev, currentPage: newPage }));

  const handleSortChange = (field, direction) => {
    setFilters((prev) => ({
      ...prev,
      sortByField: field,
      sortByValue: direction,
      currentPage: 1
    }));
  };

  const handleSearchChange = (event) => {
    setFilters((prev) => ({
      ...prev,
      searchText: event.target.value,
      currentPage: 1
    }));
  };

  const handleDateFilterChange = (date) => {
    setFilters((prev) => ({ ...prev, dateRange: date, currentPage: 1 }));
  };

  const handleStatusChange = (event) => {
    setFilters((prev) => ({
      ...prev,
      status: event.target.value,
      currentPage: 1
    }));
  };

  const handleSelectAll = (checked) => {
    dispatch(setAllSelected({ value: checked }));
  };
  const handleSelectRow = (id, checked) => {
    dispatch(setSelected({ id, value: checked }));
  };

  const clearFilters = () => {
    setFilters({
      searchText: '',
      dateRange: [null, null],
      status: '',
      currentPage: 1,
      sortByField: 'createdAt',
      sortByValue: -1
    });
  };

  const columns = [
    {
      label: 'ID',
      field: 'order_id',
      sortable: true,
      render: (item) => (
        <Link to={`/orders/${item.id}`}>{item?.order_id}</Link>
      )
    },
    {
      label: 'Vehicle',
      field: 'vehicle',
      sortable: true,
      render: (item) => (
        <Link
          to={`/orders/${item.id}`}
        >{`${item?.vehicle.make} ${item?.vehicle.model} ${item?.vehicle?.year}`}</Link>
      )
    },
    {
      label: 'User',
      field: 'user_id',
      sortable: false,
      render: (item) => item?.user?.full_name ?? item?.guestUserDetails?.name
    },
    {
      label: 'Amount',
      field: 'amount',
      sortable: false,
      render: (item) => Math.round(item?.amount)
    },
    {
      label: 'Paid Amount',
      field: 'paid_amount',
      sortable: false,
      render: (item) => Math.round(Number(item?.amount) - Number(item?.balanceAmount))
    },
    {
      label: 'Status',
      field: 'status',
      sortable: true,
      render: (item) => (
        <Badge color={getOrderStatusColor(item?.status)}>{capitalize(item?.status.toString())}</Badge>
      )
    },
    {
      label: 'Created At',
      field: 'createdAt',
      sortable: true,
      render: (item) => new Date(item?.createdAt).toLocaleString()
    },
    {
      label: "Actions",
      field: 'avtions',
      sortable: false,
      render: (item) => <OptionsMenu order={item} handleOpenDialog={handleOpenDialog} />

    }
  ];

  const [activeMenuDialog, setActiveMenuDialog] = useState(null);

  const [selectedOrder, setSelectedOrder] = useState(null);

  const handleOpenDialog = (type, order) => {
    console.log("Selected order :: ", order);
    setSelectedOrder(order);
    setActiveMenuDialog(type);
  };


  // Function to close the dialog
  const handleCloseDialog = (e) => {
    // selectedOrder = null;
    setActiveMenuDialog(null);
  };

  const menuItems = {
    UPDATE_USER: 'UPDATE_USER',
    TRANSACTION: 'TRANSACTION',
    SOLD: "SOLD",
    CANCEL: "CANCEL",
    DISPATCH: "DISPATCH"
  }

  const addTransactionStatus = useSelector((state) => state.addTransaction, (a, b) => a.addTransaction?.orderStatus !== b.addTransaction?.orderStatus);
  const cancelOrderStatus = useSelector((state) => state.cancelOrder, (a, b) => a.addTransaction?.orderStatus !== b.addTransaction?.orderStatus);
  const dispatchVehicleStatus = useSelector((state) => state.dispatchVehicle, (a, b) => a.dispatchVehicle?.orderStatus !== b.dispatchVehicle?.orderStatus);
  const markSoldStatus = useSelector((state) => state.markAsSold, (a, b) => a.markAsSold?.orderStatus !== b.markAsSold?.orderStatus);
  const updateUserOrderStatus = useSelector((state) => state.updateUserOrder, (a, b) => a.updateUserOrder?.orderStatus !== b.updateUserOrder?.orderStatus);

  useEffect(() => {
    console.log("Effect triggered :: ", addTransactionStatus);
    if (addTransactionStatus.orderStatus === 'success') {
      toast.success('Transaction added successfully!');
      fetchData()
    } else if (addTransactionStatus.orderStatus === 'error') {
      toast.error(addTransactionStatus.error ?? 'Failed to add transaction!');
    }
  }, [addTransactionStatus]);


  useEffect(() => {
    console.log("Effect triggered :: ", cancelOrderStatus);
    if (cancelOrderStatus.orderStatus === 'success') {
      toast.success('Order cancellation iniated successfully!');
      fetchData()

    } else if (cancelOrderStatus.orderStatus === 'error') {
      toast.error(cancelOrderStatus.error ?? 'Failed to cancel order.Please contact Ravo support center!');
    }
  }, [cancelOrderStatus]);

  useEffect(() => {
    console.log("Effect triggered :: ", dispatchVehicleStatus);
    if (dispatchVehicleStatus.orderStatus === 'success') {
      toast.success('Vehicle marked as delivered!');
      fetchData()

    } else if (dispatchVehicleStatus.orderStatus === 'error') {
      toast.error(dispatchVehicleStatus.error ?? 'Failed to mark vehicle as delivered!');

    }
  }, [dispatchVehicleStatus]);

  useEffect(() => {
    console.log("Effect triggered :: ", markSoldStatus);
    if (markSoldStatus.orderStatus === 'success') {
      toast.success('Vehicle marked as SOLD!');
      fetchData()

    } else if (markSoldStatus.orderStatus === 'error') {
      toast.error(markSoldStatus.error ?? 'Failed to mark vehicle as SOLD!');
    }
  }, [markSoldStatus]);

  
  useEffect(() => {
    console.log("Effect triggered :: ", updateUserOrderStatus);
    if (updateUserOrderStatus.orderStatus === 'success') {
      toast.success('User details updated successfully');
      fetchData()
    } else if (updateUserOrderStatus.orderStatus === 'error') {
      toast.error(updateUserOrderStatus.error ?? 'Failed to update user!!');
    }
  }, [updateUserOrderStatus]);


  return (
    <React.Fragment>
      <Content>
        <FilterSection
          filters={filters}
          handleDateRangeChange={handleDateFilterChange}
          handleSearchChange={handleSearchChange}
          handleStatusChange={handleStatusChange}
          clearFilters={clearFilters}
        />

        <TableComponent
          data={data}
          columns={columns}
          totalCount={totalCount}
          perPage={perPage}
          selectable={false}
          currentPage={filters.currentPage}
          onPageChange={handlePageChange}
          onSortChange={handleSortChange}
          loading={fetchOrderStatus === 'loading'}
          selectedRows={selected}
          onSelectAll={handleSelectAll}
          onSelectRow={handleSelectRow}
          
        />

        {activeMenuDialog === menuItems.UPDATE_USER && selectedOrder && (<UpdateUserInfo order={selectedOrder} open={activeMenuDialog === menuItems.UPDATE_USER} onClose={handleCloseDialog} />)}

        {activeMenuDialog === menuItems.TRANSACTION && selectedOrder && (<AddTransaction order={selectedOrder} open={activeMenuDialog === menuItems.TRANSACTION} onClose={handleCloseDialog} />)}
        {activeMenuDialog === menuItems.SOLD && (<MarkAsSold order={selectedOrder} open={activeMenuDialog === menuItems.SOLD} onClose={handleCloseDialog} onAddTransactionClicked={
          (e) => handleOpenDialog(menuItems.TRANSACTION, selectedOrder)
        } />)}
        {activeMenuDialog === menuItems.CANCEL && (<CancelOrder order={selectedOrder} open={activeMenuDialog === menuItems.CANCEL} onClose={handleCloseDialog} />)}
        {activeMenuDialog === menuItems.DISPATCH && (<DispatchVehicle order={selectedOrder} open={activeMenuDialog === menuItems.DISPATCH} onClose={handleCloseDialog} onAddTransactionClicked={
          (e) => handleOpenDialog(menuItems.TRANSACTION, selectedOrder)
        } />)}
      </Content>
    </React.Fragment>
  );
};

export default OrdersListPage;
