import Axios from 'axios';
import { apiUrl } from './configEnv';
import { store } from '../redux/store';
import { useDispatch } from 'react-redux';
import usersSlice from '../redux/features/usersSlice/usersSlice';

const axios = Axios.create({
  baseURL: apiUrl,
  headers: {
    'Content-Type': 'application/json'
  },
  withCredentials: true
});

// Create a subject for handling session expiry
const createSessionExpiredSubject = () => {
  let subscribers = [];
  return {
    subscribe: (subscriber) => {
      subscribers.push(subscriber);
      return () => {
        subscribers = subscribers.filter(sub => sub !== subscriber);
      };
    },
    notify: () => {
      subscribers.forEach(subscriber => subscriber());
    }
  };
};

export const sessionExpiredSubject = createSessionExpiredSubject();

axios.interceptors.request.use((config) => {
  const user = store.getState().auth.loggedInUser;
  if (user) {
    config.headers['Authorization'] = 'Bearer ' + user.token;
  } else {
    config.headers['Authorization'] = null;
  }
  return config;
});

axios.interceptors.response.use(
  (res) => res,
  async (err) => {
    const prevReq = err?.config;

    if (err?.response?.status === 419) {
      // Notify all subscribers about session expiry
      sessionExpiredSubject.notify();
      // Return a rejected promise with a specific error
      return Promise.reject(new Error('Session expired'));
    }else if( err?.response?.status === 401 && !prevReq._retry) {
        window.location.href = '/auth-login';
        return Promise.reject(new Error('Unauthorized'));
    }


    if (err.response) {
      const errorMessage = err.response.data?.message || 'An error occurred';
      return Promise.reject(new Error(errorMessage));
    }
    return Promise.reject(err);
  }
);

export { axios };