import React, { useState } from 'react';
import { Upload } from 'lucide-react';
import Content from '../../layout/content/Content';
import { axios } from '../../utils/axios';
import { Progress } from 'reactstrap';
import { apiUrl } from '../../utils/configEnv';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

const CSVUploader = ({}) => {
  const [file, setFile] = useState(null);
  const [headers, setHeaders] = useState([]);
  const [preview, setPreview] = useState([]);
  const [mapping, setMapping] = useState({});
  const [error, setError] = useState('');
  const [uploading, setUploading] = useState(false);
  const [uploadProgress , setUploadProgress] = useState(0);
  const [statusMessage , setStatusMessage] = useState('');

  const expectedFields = [
    'make',
    'model',
    'year',
    'mileage',
    'vin',
    'cylinder',
    'engine',
    'fuel',
    'transmission',
    'specs',
    'insurance',
    'type',
    'drive_train',
    'tag',
    'auction_starting_price',
    'direct_sale_price',
    'images'
  ];

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file && file.type === 'text/csv') {
      setFile(file);
      const reader = new FileReader();
      reader.onload = (e) => {
        const text = e.target.result;
        const lines = text.split('\n');
        const headers = lines[0].split(',').map((h) => h.trim());
        setHeaders(headers);

        // Create initial mapping
        const initialMapping = {};
        headers.forEach((header) => {
          const matchedField = expectedFields.find(
            (field) => field.toLowerCase() === header.toLowerCase()
          );
          if (matchedField) {
            initialMapping[header] = matchedField;
          }
        });

        console.log(initialMapping)

        setMapping(initialMapping);

        // Preview first 5 rows
        const previewData = lines
          .slice(1, 6)
          .map((line) => line.split(',').map((cell) => cell.trim()));
        setPreview(previewData);
      };
      reader.readAsText(file);
      setError('');
    } else {
      setError('Please upload a valid CSV file');
    }
  };
  const loggedInUser = useSelector((state) => state.auth.loggedInUser);

  const handleSubmit = async () => {
    try {
      setUploading(true);
      setUploadProgress(0);
      const formData = new FormData();
      formData.append('file', file);
      formData.append('mapping', JSON.stringify(mapping));
  
      const response = await fetch(`${apiUrl}device/api/v1/cars/bulk-upload`, {
        method: 'POST',
        body: formData,
        headers:{
          'Authorization': `Bearer ${loggedInUser.token}`
        },
      });
  
      if (!response.ok) {
        throw new Error('Upload failed');
      }
  
      const reader = response.body.getReader();
      const decoder = new TextDecoder();
  
      while (true) {
        const { value, done } = await reader.read();
        if (done) break;
        
        const chunk = decoder.decode(value);
        const lines = chunk.split('\n\n');
        
        for (const line of lines) {
          if (line.startsWith('data: ')) {
            try {
              const eventData = JSON.parse(line.slice(6));
              
              switch (eventData.status) {
                case 'processing':
                  setUploadProgress(eventData.percentage);
                  setStatusMessage(`Processing rows (${eventData.processedRows}/${eventData.totalRows})`);
                  break;
                  
                case 'saving':
                  setUploadProgress(95);
                  setStatusMessage(eventData.message);
                  break;
                  
                case 'completed':
                  setUploadProgress(100);
                  
                  setStatusMessage(`Successfully processed ${eventData.data.totalProcessed} records`);
                  toast.success(`Successfully processed ${eventData.data.totalProcessed} records`);
                  // Reset form
                  setFile(null);
                  setPreview([]);
                  setMapping({});
                  break;
                  
                case 'error':
                  throw new Error(eventData.error);
              }
            } catch (e) {
              console.error('Error parsing progress update:', e);
              setError(e.message);
            }
          }
        }
      }
  
    } catch (err) {
      setError(err.message);
    } finally {
      setUploading(false);
    }
  };

  return (
    <React.Fragment>
      <Content>
        <div className="container p-4 bg-white rounded shadow-sm">
          <div className="border-bottom pb-3 mb-4">
            <h2 className="h4 text-dark">Bulk Upload Cars</h2>
          </div>

          <div className="mb-4">
            {/* Upload Area */}
            <div
              className="border border-dashed rounded p-5 text-center"
              style={{ borderColor: '#6c757d' }}
            >
              <input
                type="file"
                onChange={handleFileUpload}
                accept=".csv"
                className="d-none"
                id="csv-upload"
              />
              <label htmlFor="csv-upload" className="cursor-pointer">
                <Upload
                  className="mx-auto mb-2 text-muted"
                  style={{ fontSize: '2rem' }}
                />
                <p className="mb-1">Click to upload CSV or drag and drop</p>
                <p className="small text-muted">CSV files only</p>
              </label>
            </div>
          </div>

          {/* Error Message */}
          {error && (
            <div className="alert alert-danger" role="alert">
              {error}
            </div>
          )}

          {/* Preview Table */}
          {preview?.length > 0 && (
            <div className="table-responsive">
              <table className="table table-bordered">
                <thead>
                  <tr>
                    {headers.map((val, index) => (
                      <th key={index} style={{ minWidth: '150px' }}>
                        <select
                          value={mapping[val]?? ''}
                          onChange={(e) =>
                            {
                              return setMapping({
                                ...mapping,
                                [val]: e.target.value
                              });
                            }
                          }
                          className="form-select"
                        >
                          <option value="">Skip</option>
                          {expectedFields.map((field) => (
                            <option key={field} value={field}>
                              {field}
                            </option>
                          ))}
                        </select>
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {preview.map((row, i) => (
                    <tr key={i}>
                      {row.map((cell, j) => (
                        <td key={j} >{cell}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
          {uploading && (
  <div className="mb-4">
    <div className="flex justify-between mb-2">
      <span className="text-sm text-gray-600">{statusMessage}</span>
      <span className="text-sm text-gray-600">{uploadProgress}%</span>
    </div>
    <Progress value={uploadProgress} className="h-2" />
  </div>
)}

          {/* Submit Button */}
          {file && (
            <div className="d-flex justify-content-end">
              <button
                onClick={handleSubmit}
                disabled={uploading}
                className={`btn btn-${uploading ? 'secondary' : 'primary'}`}
              >
                {uploading ? 'Uploading...' : 'Upload Cars'}
              </button>
            </div>
          )}
        </div>
      </Content>
    </React.Fragment>
  );
};

export default CSVUploader;
