import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  Badge,
  Col,
  Spinner
} from 'reactstrap';
import { clearItems } from '../../redux/features/itemsSlice/itemsSlice';
import Content from '../../layout/content/Content';
import TableComponent from '../../components/table/TableComponent';
import { getColor } from '../../utils/getColor';
import { startCase } from 'lodash';
import { fetchAllPayments, fetchDebouncedAllPayments } from '../../redux/features/usersSlice/paymentSlice';
import FilterSection from './components/FilterSection';
import Invoice from './invoice';

const PaymentsPage = () => {
  const dispatch = useDispatch();
  const [filters, setFilters] = useState({
    searchText: '',
    status: '',
    dateRange: [null, null],
    currentPage: 1,
    sortByField: 'createdAt',
    sortByValue: -1,
    vertical: ''
  });

  const { data, totalCount, loading, perPage } = useSelector(
    (state) => state.payments
  );
  const user = useSelector((state) => state.userDetails.data);
  const verticals = useSelector((state) => state.verticals);

  // Fetch data based on filters
  const fetchData = useCallback(() => {
    const {
      currentPage,
      sortByField,
      sortByValue,
      status,
      dateRange,
      searchText,
      vertical
    } = filters;

    const requestData = {
      query: {},
      options: {
        page: currentPage,
        limit: perPage,
        sort: sortByValue === 1 ? sortByField : `-${sortByField}`,
        populate: [
          { path: 'user_id', select: 'full_name' },
          { path: 'item_id', select: 'title' },
          { path: 'auction_id', select: '_id' },
          { path: 'vertical_id', select: 'name' }
        ]
      }
    };

    if (user?.id) {
      requestData.query.user = user?.id;
    }

    if (status) {
      requestData.query.status = status;
    }

    if (dateRange) {
      requestData.query.date = {};
      if (dateRange[0]) {
        requestData.query.date.from = dateRange[0].toISOString();
      }
      if (dateRange[1]) {
        requestData.query.date.to = dateRange[1].toISOString();
      }
    }

    if (searchText !== '') {
      requestData.query.searchId = searchText.trim();
      dispatch(fetchDebouncedAllPayments(requestData));
    } else {
      dispatch(fetchAllPayments(requestData));
    }
  }, [filters, dispatch, user?.id, perPage]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    return () => {
      dispatch(clearItems());
    };
  }, [dispatch]);

  // Handle filters
  const handlePageChange = (newPage) =>
    setFilters((prev) => ({ ...prev, currentPage: newPage }));

  const handleSortChange = (field, direction) => {
    setFilters((prev) => ({
      ...prev,
      sortByField: field,
      sortByValue: direction,
      currentPage: 1
    }));
  };

  const handleSearchChange = (event) => {
    setFilters((prev) => ({
      ...prev,
      searchText: event.target.value,
      currentPage: 1
    }));
  };

  const handleDateFilterChange = (dates) => {
    setFilters((prev) => ({ ...prev, dateRange: dates, currentPage: 1 }));
  };

  const handleStatusChange = (event) => {
    setFilters((prev) => ({
      ...prev,
      status: event.target.value,
      currentPage: 1
    }));
  };

  const clearFilters = () => {
    setFilters({
      searchText: '',
      dateRange: [null, null],
      status: '',
      currentPage: 1,
      sortByField: 'createdAt',
      sortByValue: -1,
      vertical: ''
    });
  };

  const columns = [
    {
      label: 'ID',
      field: 'id',
      sortable: true,
      render: (item) => (
        <Invoice item={item} />
      )
    },
    {
      label: 'User',
      field: 'user_id',
      sortable: false,
      render: (item) => (
        <Link to={`/users/${item?.user_id?.id}/`}>
          {startCase(item?.user_id?.full_name)} 
          {item?.user_id?.id === item?.dealer_id && <span>(You)</span>}
        </Link>
      )
    },
    {
      label: 'Payment For',
      field: 'payment_for',
      sortable: false,
      render: (item) => item?.payment_for
    },
    {
      label: 'Order #',
      field: 'order_id',
      sortable: false,
      render: (item) => (
        <Link to={`orders/${item?.order_id?.id}`} className="text-truncate d-inline-block" style={{ maxWidth: '30ch' }}>
          {startCase(item?.order_id?.order_id)}
        </Link>
      )
    },
    {
      label: 'Amount',
      field: 'amount',
      sortable: true,
      render: (item) => item?.amount
    },
    {
      label: 'Status',
      field: 'status',
      sortable: true,
      render: (item) => (
        <Badge color={getColor(item?.status)}>
          {startCase(item?.status)}
        </Badge>
      )
    },
    {
      label: 'Created At',
      field: 'createdAt',
      sortable: true,
      render: (item) => {
        const dateOptions = {
          weekday: 'short',
          month: 'short',
          day: 'numeric',
          year: 'numeric'
        };
        const timeOptions = { hour: '2-digit', minute: '2-digit' };
        
        return (
          <span>
            {new Date(item?.createdAt).toLocaleDateString(undefined, dateOptions)}{' '}
            {new Date(item?.createdAt).toLocaleTimeString(undefined, timeOptions)}
          </span>
        );
      }
    }
  ];

  return (
    <React.Fragment>
      <Content>
        <FilterSection
          filters={filters}
          handleDateRangeChange={handleDateFilterChange}
          handleSearchChange={handleSearchChange}
          handleStatusChange={handleStatusChange}
          clearFilters={clearFilters}
          totalCount={totalCount}
          verticals={verticals}
        />

        <TableComponent
          data={data?.data || []}
          columns={columns}
          totalCount={totalCount}
          perPage={perPage}
          currentPage={filters.currentPage}
          onPageChange={handlePageChange}
          onSortChange={handleSortChange}
          loading={loading}
          selectable={false}
        />

        {!data?.data?.length && !loading && (
          <div className="text-center mt-4">
            <span className="text-silent">No payments found</span>
          </div>
        )}

        {loading && (
          <div className="text-center mt-4">
            <Spinner color="primary" />
          </div>
        )}
      </Content>
    </React.Fragment>
  );
};

export default PaymentsPage;