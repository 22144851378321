import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  Grid2,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Switch,
  Typography
} from '@mui/material';

import React, { useState } from 'react';
import { updateUserOrder } from '../../../redux/features/ordersSlice/orderUpdateSlice';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import FormPhoneField from '../../../components/form/FormPhoneField';
import { Col, FormTextField } from '../../../components/Component';

function UpdateUserInfo({ order, open, onClose }) {
  const methods = useForm();
  const { handleSubmit, control } = methods;

  const [error, setError] = useState({
    name: null,
    phone: null
  });
  const dispatch = useDispatch();

  const handleDialogClose = (e) => {
    setError(null);
    console.log(typeof onClose);
    onClose?.call();
  };

  const onSubmit = (data) => {
    updateUserDetails(order, data.full_name, data.phone);
    onClose();
  };

  const updateUserDetails = (order, userName, userPhone) => {
    dispatch(
      updateUserOrder({
        orderId: order.id,
        userName: userName,
        userPhone: userPhone
      })
    );
  };
  return (
    <>
      <Dialog
        open={open}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
            Update User
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Grid2>
            <FormTextField
              label="Full Name*"
              name="full_name"
              control={control}
            />
            {error.name && (
              <Typography variant="body2" color="error">
                {error.name}
              </Typography>
            )}
          </Grid2>

          <Grid2>
            <FormPhoneField label="Phone*" name="phone" control={control} />
            {error.phone && (
              <Typography variant="body2" color="error">
                {error.phone}
              </Typography>
            )}
          </Grid2>
          <DialogActions>
            <Button onClick={handleDialogClose}>Cancel</Button>
            <Button type="button" onClick={handleSubmit(onSubmit)}>
              Save
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default UpdateUserInfo;
